<template>
  <b-card class="col-md-6 mx-auto">
    <div class="text-center">
      <span class="svg-icon svg-icon-primary svg-icon-10x mb-5"
        ><!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2021-05-14-112058/theme/html/demo1/dist/../src/media/svg/icons/Files/Deleted-file.svg--><svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="24px"
          height="24px"
          viewBox="0 0 24 24"
          version="1.1"
        >
          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <polygon points="0 0 24 0 24 24 0 24" />
            <path
              d="M5.85714286,2 L13.7364114,2 C14.0910962,2 14.4343066,2.12568431 14.7051108,2.35473959 L19.4686994,6.3839416 C19.8056532,6.66894833 20,7.08787823 20,7.52920201 L20,20.0833333 C20,21.8738751 19.9795521,22 18.1428571,22 L5.85714286,22 C4.02044787,22 4,21.8738751 4,20.0833333 L4,3.91666667 C4,2.12612489 4.02044787,2 5.85714286,2 Z"
              fill="#000000"
              fill-rule="nonzero"
              opacity="0.3"
            />
            <path
              d="M10.5857864,13 L9.17157288,11.5857864 C8.78104858,11.1952621 8.78104858,10.5620972 9.17157288,10.1715729 C9.56209717,9.78104858 10.1952621,9.78104858 10.5857864,10.1715729 L12,11.5857864 L13.4142136,10.1715729 C13.8047379,9.78104858 14.4379028,9.78104858 14.8284271,10.1715729 C15.2189514,10.5620972 15.2189514,11.1952621 14.8284271,11.5857864 L13.4142136,13 L14.8284271,14.4142136 C15.2189514,14.8047379 15.2189514,15.4379028 14.8284271,15.8284271 C14.4379028,16.2189514 13.8047379,16.2189514 13.4142136,15.8284271 L12,14.4142136 L10.5857864,15.8284271 C10.1952621,16.2189514 9.56209717,16.2189514 9.17157288,15.8284271 C8.78104858,15.4379028 8.78104858,14.8047379 9.17157288,14.4142136 L10.5857864,13 Z"
              fill="#000000"
            />
          </g></svg
        ><!--end::Svg Icon--></span
      >
      <h1 class="mt-7 font-weight-bolder">Delete Application</h1>
      <p>
        Please type the application's package name below to confirm deleting
        this application.
        <font color="red">
          Please note this action cannot be undone and will no longer be listed.
        </font>
      </p>

      <b-form-input
        class="
          form-control form-control-solid
          h-auto
          py-3
          px-3
          mb-5
          text-center
          col-md-11
          mx-auto
        "
        type="text"
        id="packageName"
        name="packageName"
        placeholder="Package Name"
        v-model="packageName"
      ></b-form-input>
      <div
        class="btn btn-secondary font-weight-bolder"
        @click="$router.push('/applications')"
      >
        <span class="svg-icon svg-icon-secondary svg-icon-2x"
          ><!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2021-05-14-112058/theme/html/demo1/dist/../src/media/svg/icons/Navigation/Close.svg--><svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            version="1.1"
          >
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g
                transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)"
                fill="#000000"
              >
                <rect x="0" y="7" width="16" height="2" rx="1" />
                <rect
                  opacity="0.3"
                  transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) "
                  x="0"
                  y="7"
                  width="16"
                  height="2"
                  rx="1"
                />
              </g>
            </g></svg
          ><!--end::Svg Icon--></span
        >Cancel
      </div>
      <div
        class="btn btn-danger ml-5 font-weight-bolder"
        :class="isPackageNotEmpty ? '' : 'disabled'"
      >
        <span class="svg-icon svg-icon-white svg-icon-2x"
          ><!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2021-05-14-112058/theme/html/demo1/dist/../src/media/svg/icons/Home/Trash.svg--><svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            version="1.1"
          >
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <rect x="0" y="0" width="24" height="24" />
              <path
                d="M6,8 L18,8 L17.106535,19.6150447 C17.04642,20.3965405 16.3947578,21 15.6109533,21 L8.38904671,21 C7.60524225,21 6.95358004,20.3965405 6.89346498,19.6150447 L6,8 Z M8,10 L8.45438229,14.0894406 L15.5517885,14.0339036 L16,10 L8,10 Z"
                fill="#000000"
                fill-rule="nonzero"
              />
              <path
                d="M14,4.5 L14,3.5 C14,3.22385763 13.7761424,3 13.5,3 L10.5,3 C10.2238576,3 10,3.22385763 10,3.5 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                fill="#000000"
                opacity="0.3"
              />
            </g></svg
          ><!--end::Svg Icon--></span
        >Delete Application
      </div>
    </div>
  </b-card>
</template>
<script>
export default {
  data() {
    return {
      application: null,
      applicationId: null,
      packageName: null,
    };
  },
  methods: {
    getApplications() {},
  },
  computed: {
    isPackageNotEmpty() {
      return this.packageName !== null && this.packageName.trim().length > 0;
    },
  },
};
</script>